import React, { useEffect } from "react";

// styles import
import styles from "./Organization.module.scss";

// components import
import OrganizationHeader from "./OrganizationHeader";
import OrganizationTable from "./OrganizationTable";
import OrganizationPagination from "./OrganizationPagination";
import OrganizationMobileRows from "./OrganizationMobileRows";
// import languageConstant from "../../resources/constants/StringConstants";
// store import
import { useDispatch } from "react-redux";
import { changeOrganizationData, resetOrganizationPayload } from "../../store/OrganizationSlice";

// constants import
import { DummyTableData, constantOrganizationPayload } from "../../utils/ConstantObjects";
export default function Organization() {
  const isMobile = window.innerWidth < 768; // threshold for mobile devices

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(changeOrganizationData(DummyTableData));
      dispatch(resetOrganizationPayload(constantOrganizationPayload));
    };
  }, []);
  if (sessionStorage.getItem("role") !== "Super Admin") {
    return <div>You are not authorized to see this page</div>;
  }

  return (
    <div className={styles["organization-main-container"]}>
      <OrganizationHeader />
      {!isMobile && (
        <div className={styles["organization-table-visibility"]}>
          <OrganizationTable />
        </div>
      )}
      {isMobile && (
        <div className={styles["organization-card-visibility"]}>
          <OrganizationMobileRows />
        </div>
      )}
      {!isMobile && <OrganizationPagination />}
    </div>
  );
}
