import React, { useEffect } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";

// custom hook import
import { useAuthState } from "../hooks/useAuthState.js";

// constants import
import {
  PATH_CHATS,
  PATH_LOGIN,
  PATH_ORGANIZATION,
  PATH_USERS,
  PATH_INSIGHTS,
  PATH_REPORTS,
  PATH_CLINICPROFILE,
  PATH_LASTLOGIN,
} from "../resources/constants/RoutesPathConstants";

// components import
import Login from "../components/login/Login.js";
import ErrorPage from "../components/errorPage/ErrorPage.js";
import NavigationMenu from "../components/navigationMenu/NavigationMenu.js";
import Chats from "../components/chats/Chats.js";
import Insights from "../components/insights/Insights.js";
import Reports from "../components/reports/Reports.js";
import ClinicProfile from "../components/clinicprofile/ClinicProfile.js";
import LastLogin from "../components/lastLogin/LastLogin.js"

// store import
import Users from "../components/users/Users.js";
import Organization from "../components/organization/Organization.js";
import { constantOrgAliasPayload } from "../utils/ConstantObjects.js";
import { OK } from "../resources/constants/ResponseCode.js";
import { useDispatch, useSelector } from "react-redux";
import { changeOrganizationAlias } from "../store/UsersSlice.js";

// api import
import { getOrganizationLabels } from "../services/apiServices/OrganizationAPI.js";




export default function RoutesComponent() {
  const location = window.location.pathname;
  const { isLoggedIn } = useAuthState();
  const dispatch = useDispatch();
  const isMobileMenuOpen = useSelector((store) => store.config.isMobileMenuOpen);
  const getOrganizationLabel = async () => {
    try {
      const data = await getOrganizationLabels(constantOrgAliasPayload);
      if (data?.status === OK ){
        dispatch(changeOrganizationAlias(data?.data?.provider_alias));
      }

      return !data?.status === OK;
      // adding ! to satisty isError flag to render something went wrong
    } catch (error) {

      return true;
      // returning true to render something went wrong page
      // empty catch block since error is already handled in interceptors
    } 
  };
  useEffect(() => {
    if (sessionStorage.getItem("role") !== "Super Admin") {getOrganizationLabel()}
  }, []);
  const appRouter = createBrowserRouter([
    {
      path: PATH_LOGIN,
      element: (
        <div className="App">
          {isLoggedIn === "true" && location !== PATH_LOGIN && (
            <div className="header">
              <NavigationMenu />
            </div>
          )}
          <div
            className={`${isLoggedIn === "true" && location !== PATH_LOGIN ? "outlet" : ""}
          ${isMobileMenuOpen ? "outlet-blur" : ""}`}
          >
            <Outlet />
          </div>
        </div>
      ),
      children: [
        {
          path: PATH_LOGIN,
          element: <Login />,
        },
        {
          path: PATH_INSIGHTS,
          element: <Insights />,
        },
        {
          path: PATH_CHATS,
          element: <Chats />,
        },
        {
          path: PATH_USERS,
          element: <Users />,
        },
        {
          path: PATH_ORGANIZATION,
          element: <Organization />,
        },
        {
          path: PATH_REPORTS,
          element: <Reports />,
        },
        {
          path: PATH_CLINICPROFILE,
          element: <ClinicProfile />,
        },
        {
          path: PATH_LASTLOGIN,
          element: <LastLogin />,
        },
      ],
      errorElement: (
        <div className="App">
          {isLoggedIn === "true" && location !== PATH_LOGIN && (
            <div className="header">
              <NavigationMenu />
            </div>
          )}
          <div className="outlet">
            <ErrorPage />
          </div>
        </div>
      ),
    },
  ]);

  return <RouterProvider router={appRouter}></RouterProvider>;
}
