import React, { useEffect } from "react";
import { Typography } from "antd";

// styles import
import styles from "./Chats.module.scss";

// constants import
import languageConstant from "../../resources/constants/StringConstants";
import { DummyTableData, constantChatPayload } from "../../utils/ConstantObjects.js";

// store import
import { useDispatch, useSelector } from "react-redux";
import {
  changeAddResponderModalPayload,
  changeChatsModalChatSessionId,
  changeChatsTableData,
  changeReassignPayload,
  resetChatsPayloadFilter,
} from "../../store/ChatSlice.js";

// component import
import ChatsTable from "./ChatsTable.js";
import ChatsPagination from "./ChatsPagination.js";
import ChatsFilters from "./ChatsFilters";
export default function Chats() {
  const langKey = useSelector((store) => store.config.lang);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      //runs during unmounting of component.
      dispatch(resetChatsPayloadFilter(constantChatPayload));
      dispatch(changeChatsTableData(DummyTableData));
      dispatch(changeChatsModalChatSessionId(null));
      /* eslint-disable camelcase */
      dispatch(
        changeReassignPayload({
          specialty: null,
          user_id: null,
          session_id: null,
          org_id: null,
          type: null,
          authToken:  sessionStorage.getItem("authToken") || null,
        })
      );
      /* eslint-disable camelcase */
      dispatch(changeAddResponderModalPayload(null));
    };
  }, []);

  return (
    <div className={styles["chats-main-container"]}>
      <div className={styles["chats-heading-container"]}>
        <Typography className={styles["chats-heading"]}>
          {languageConstant[langKey].chats}
        </Typography>
      </div>
      <ChatsFilters />
      <ChatsTable />
      <ChatsPagination />
    </div>
  );
}
