/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Skeleton, Table, Tooltip } from "antd";
import { DateTime } from "luxon";

// styles import
import styles from "./Chats.module.scss";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  // changeAddResponderModalPayload,
  // changeAddResponderModalVisibility,
  changeChatsModalChatSessionId,
  changeChatsModalVisibility,
  changeIsErrorStatus,
  changeReassignModalVisibility,
  changeReassignPayload,
  // changeRemoveResponderModalVisibility,
  changeVideoLinkModalVisibility,
  updateVideoLink,
} from "../../store/ChatSlice";

// constants import
import languageConstant from "../../resources/constants/StringConstants";
import { constantDateAndTimeFormat } from "../../utils/ConstantObjects";
import addIcon from "../../resources/assets/addIcon.svg";
import deleteIcon from "../../resources/assets/deleteIcon.svg";
/* eslint-enable no-unused-vars */
// api import
import { ChatsFetch } from "./ChatsFetch";

// component import
import ChatsModal from "./ChatsModal";
import VideoLinkModal from "./VideoLinkModal";
import ReassignModal from "./ReassignModal";
import AddResponderModal from "./AddResponderModal";
import RemoveResponderModal from "./RemoveResponderModal";
import SomethingWentWrong from "../errorPage/SomethingWentWrong";

export default function ChatsTable() {
  const langKey = useSelector((store) => store.config.lang);
  const {
    chatsTableData,
    isLoading,
    chatsIsError,
    body,
    renderChatsModal,
    renderVideoLinkModal,
    renderReassignModal,
    renderAddResponderModal,
    renderRemoveResponderModal,
    submitterAlias,
  } = useSelector(
    (store) => ({
      chatsTableData: store.chats.chatsTableData,
      isLoading: store.chats.isLoading,
      chatsIsError: store.chats.isError,
      body: store.chats.chatsPayload,
      renderChatsModal: store.chats.chatsModal.visible,
      renderVideoLinkModal: store.chats.videoLinkModal.visible,
      renderReassignModal: store.chats.reassignModal.visible,
      renderAddResponderModal: store.chats.addResponderModal.visible,
      renderRemoveResponderModal: store.chats.removeResponderModal.visible,
      submitterAlias: store.users.submitterAlias,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const chatsFetchFuncton = async () => {
    const isErrorFlag = await ChatsFetch(dispatch, body);
    dispatch(changeIsErrorStatus(isErrorFlag));
  };
  useEffect(() => {
    chatsFetchFuncton();
  }, []);

  const columns = sessionStorage.getItem("role") == "Super Admin" ?[
    {
      title: languageConstant[langKey].chatId,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : `${record["afya_id"] || languageConstant[langKey].noData}`.trim() ? (
          <a
            onClick={() => {
              dispatch(changeChatsModalVisibility(true));
              dispatch(changeChatsModalChatSessionId(record["id"]));
            }}
          >
            {`${record["afya_id"] || languageConstant[langKey].noData}`.trim()}
          </a>
        ) : (
          languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].videoLink,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          <a
            onClick={() => {
              dispatch(changeVideoLinkModalVisibility(true));
              dispatch(updateVideoLink(record["video_call_link"] || null));
              dispatch(changeChatsModalChatSessionId(record["id"]));
            }}
          >
            {languageConstant[langKey].generateLink}
          </a>
        ),
    },
    {
      title: languageConstant[langKey].organization,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["organization.name"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].medicalGroup,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["med_grp.name"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].submitterProvider,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          `${record["owner1.first_name"] || ""} ${record["owner1.last_name"] || ""}`
           || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].responderProvider,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          `${record["owner2.first_name"] || ""} ${record["owner2.last_name"] || ""}`.trim() ||
          languageConstant[langKey].noData
        ),
    },
    
    {
      title: languageConstant[langKey].specialty,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["spec_master.name"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].consumer,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          `${record["patient.first_name"] || ""} ${record["patient.last_name"] || ""}`.trim() ||
          languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].closeCode,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["close_code.close_code"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].createdAt,
      render: (record) => {
        const formattedDateTime = DateTime.fromISO(record["createdAt"], {
          zone: "America/Los_Angeles",
        }).toLocaleString(constantDateAndTimeFormat);

        return isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : formattedDateTime !== "Invalid DateTime" ? (
          formattedDateTime
        ) : (
          languageConstant[langKey].noData
        );
      },
    },
    // {
    //   title: languageConstant[langKey].firstRespondedAt,
    //   render: (record) => {
    //     const formattedDateTime = DateTime.fromISO(
    //       record["responder_responce_times"]?.[0]?.["timestamp"],
    //       {
    //         zone: "America/Los_Angeles",
    //       }
    //     ).toLocaleString(constantDateAndTimeFormat);

    //     return isLoading ? (
    //       <Skeleton.Input style={{ height: 12 }} active />
    //     ) : formattedDateTime !== "Invalid DateTime" ? (
    //       formattedDateTime
    //     ) : (
    //       languageConstant[langKey].noData
    //     );
    //   },
    // },
    // {
    //   title: languageConstant[langKey].respondedAt,
    //   render: (record) => {
    //     const formattedDateTime = DateTime.fromISO(record["updatedTime"], {
    //       zone: "America/Los_Angeles",
    //     }).toLocaleString(constantDateAndTimeFormat);

    //     return isLoading ? (
    //       <Skeleton.Input style={{ height: 12 }} active />
    //     ) : formattedDateTime !== "Invalid DateTime" ? (
    //       formattedDateTime
    //     ) : (
    //       languageConstant[langKey].noData
    //     );
    //   },
    // },
    {
      title: languageConstant[langKey].actions,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          <div className={styles["chats-action-container"]}>
            <a
              onClick={() => {
                dispatch(changeReassignModalVisibility(true));
                /* eslint-disable camelcase */
                dispatch(
                  changeReassignPayload({
                    specialty: record["owner1.spec_id"] || null,
                    user_id: record["owner1.user_id"] || null,
                    session_id: record["id"] || null,
                    org_id: record["org_id"] || null,
                    type: "admin",
                    authToken: sessionStorage.getItem("authToken") || null,
                  })
                );
                /* eslint-disable camelcase */
              }}
            >
              {languageConstant[langKey].add}
            </a>
            {/* <Tooltip title={languageConstant[langKey].addResponderToChatSession}>
              <img
                src={addIcon}
                alt="ai"
                className={styles["chats-action-img"]}
                onClick={() => {
                  dispatch(changeAddResponderModalVisibility(true));
                  dispatch(changeAddResponderModalPayload(record["org_id"] || null));
                  dispatch(changeChatsModalChatSessionId(record["id"] || null));
                }}
              />
            </Tooltip>
            <Tooltip title={languageConstant[langKey].removeUserFromChatSession}>
              <img
                src={deleteIcon}
                alt="di"
                className={styles["chats-action-img"]}
                onClick={() => {
                  dispatch(changeRemoveResponderModalVisibility(true));
                  dispatch(changeChatsModalChatSessionId(record["id"] || null));
                }}
              />
            </Tooltip> */}
          </div>
        ),
    },
  ]:[{
    title: languageConstant[langKey].chatId,
    render: (record) =>
      isLoading ? (
        <Skeleton.Input style={{ height: 12 }} active />
      ) : `${record["afya_id"] || 
      languageConstant[langKey].noData}`.trim() ? (
          <a
            onClick={() => {
              dispatch(changeChatsModalVisibility(true));
              dispatch(changeChatsModalChatSessionId(record["id"]));
            }}
          >
            {`${record["afya_id"] ||
            languageConstant[langKey].noData}`.trim()}
          </a>
        ) : (
          languageConstant[langKey].noData
        ),
  },
  {
    title: languageConstant[langKey].submitterProvider,
    render: (record) =>
      isLoading ? (
        <Skeleton.Input style={{ height: 12 }} active />
      ) : (
        `${record["owner1.first_name"] || ""} ${record["owner1.last_name"] || ""}`
         || languageConstant[langKey].noData
      ),
  },  
  {
    title: languageConstant[langKey].responderProvider,
    render: (record) =>
      isLoading ? (
        <Skeleton.Input style={{ height: 12 }} active />
      ) : (
        `${record["owner2.first_name"] || ""} ${record["owner2.last_name"] || ""}`.trim() ||
        languageConstant[langKey].noData
      ),
  },
  {
    title: languageConstant[langKey].medicalGroup,
    render: (record) => {
      // Loading...display placeholder
      if (isLoading) {
        return <Skeleton.Input style={{ height: 12 }} active />
      }
     
      // Some condition.
      const isCdcr = record["org_id"] == "700fb980-3e18-11ee-8e5a-9995b6ce38a9"
      const isNotNull = record["med_grp.name"] != null && record["med_grp.name"].startsWith("CDCR ")
      const isAdmin = record["med_grp.name"] != null &&
       record["med_grp.name"] == "CDCR California Department of Corrections and Rehabilitation"
      if (isCdcr) {
        if (isAdmin) {

          return "Admin"
        }
        if (isNotNull) {

          return record["med_grp.name"].substring(5)
        }

        return languageConstant[langKey].noData
      }

      // Return default thing.
      return  record["med_grp.name"] ||  languageConstant[langKey].noData
  
    }
    
  },
  {
    title: languageConstant[langKey].specialty,
    render: (record) =>
      isLoading ? (
        <Skeleton.Input style={{ height: 12 }} active />
      ) : (
        record["spec_master.name"] || languageConstant[langKey].noData
      ),
  },
  {
    title: languageConstant[langKey].consumer,
    render: (record) =>
      isLoading ? (
        <Skeleton.Input style={{ height: 12 }} active />
      ) : (
        `${record["patient.first_name"] || ""} ${record["patient.last_name"] || ""}`.trim() ||
        languageConstant[langKey].noData
      ),
  },
  {
    title: languageConstant[langKey].closeCode,
    render: (record) =>
      isLoading ? (
        <Skeleton.Input style={{ height: 12 }} active />
      ) : (
        record["close_code.close_code"] || languageConstant[langKey].inDialog
      ),
  },
  {
    title: languageConstant[langKey].createdAt,
    render: (record) => {
      const formattedDateTime = DateTime.fromISO(record["createdAt"], {
        zone: "America/Los_Angeles",
      }).toLocaleString(constantDateAndTimeFormat);

      return isLoading ? (
        <Skeleton.Input style={{ height: 12 }} active />
      ) : formattedDateTime !== "Invalid DateTime" ? (
        formattedDateTime
      ) : (
        languageConstant[langKey].noData
      );
    },
  },
  {
    title: languageConstant[langKey].actions,
    render: (record) =>
      isLoading ? (
        <Skeleton.Input style={{ height: 12 }} active />
      ) : (
        <div className={styles["chats-action-container"]}>
          <a
            onClick={() => {
              dispatch(changeReassignModalVisibility(true));
              /* eslint-disable camelcase */
              dispatch(
                changeReassignPayload({
                  specialty: record["owner1.spec_id"] || null,
                  user_id: record["owner1.user_id"] || null,
                  session_id: record["id"] || null,
                  org_id: record["org_id"] || null,
                  type: "admin",
                  authToken: sessionStorage.getItem("authToken") || null,
                })
              );
              /* eslint-disable camelcase */
            }}
          >
            {languageConstant[langKey].add} {submitterAlias}
          </a>
          {/* <Tooltip title={languageConstant[langKey].addResponderToChatSession}>
            <img
              src={addIcon}
              alt="ai"
              className={styles["chats-action-img"]}
              onClick={() => {
                dispatch(changeAddResponderModalVisibility(true));
                dispatch(changeAddResponderModalPayload(record["org_id"] || null));
                dispatch(changeChatsModalChatSessionId(record["id"] || null));
              }}
            />
          </Tooltip>
          <Tooltip title={languageConstant[langKey].removeUserFromChatSession}>
            <img
              src={deleteIcon}
              alt="di"
              className={styles["chats-action-img"]}
              onClick={() => {
                dispatch(changeRemoveResponderModalVisibility(true));
                dispatch(changeChatsModalChatSessionId(record["id"] || null));
              }}
            />
          </Tooltip> */}
        </div>
      ),
  },
    // {
    //   title: languageConstant[langKey].firstRespondedAt,
    //   render: (record) => {
    //     const formattedDateTime = DateTime.fromISO(
    //       record["responder_responce_times"]?.[0]?.["timestamp"],
    //       {
    //         zone: "America/Los_Angeles",
    //       }
    //     ).toLocaleString(constantDateAndTimeFormat);

    //     return isLoading ? (
    //       <Skeleton.Input style={{ height: 12 }} active />
    //     ) : formattedDateTime !== "Invalid DateTime" ? (
    //       formattedDateTime
    //     ) : (
    //       languageConstant[langKey].noData
    //     );
    //   },
    // },
    // {
    //   title: languageConstant[langKey].respondedAt,
    //   render: (record) => {
    //     const formattedDateTime = DateTime.fromISO(record["updatedTime"], {
    //       zone: "America/Los_Angeles",
    //     }).toLocaleString(constantDateAndTimeFormat);

    //     return isLoading ? (
    //       <Skeleton.Input style={{ height: 12 }} active />
    //     ) : formattedDateTime !== "Invalid DateTime" ? (
    //       formattedDateTime
    //     ) : (
    //       languageConstant[langKey].noData
    //     );
    //   },
    // },
  ];

  if (chatsIsError) return <SomethingWentWrong refetch={chatsFetchFuncton} />;

  return (
    <div>
      <div className={styles["chats-table-container"]}>
        <Table
          dataSource={chatsTableData}
          rowKey={(record) => record["id"]}
          columns={columns}
          pagination={false}
          style={{ whiteSpace: "nowrap" }}
        />
      </div>
      {renderReassignModal && <ReassignModal />}
      {renderAddResponderModal && <AddResponderModal />}
      {renderRemoveResponderModal && <RemoveResponderModal />}
      {renderChatsModal && <ChatsModal />}
      {renderVideoLinkModal && <VideoLinkModal />}
    </div>
  );
}
