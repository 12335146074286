import { React, useState, useEffect } from "react";
import { Spin } from "antd";

//constants import
import styles from "./LastLogin.module.scss";
const contentStyle = {
  padding: 50,
  borderRadius: 4,
};
// import languageConstant from "../../resources/constants/StringConstants";
const content = <div style={contentStyle} />;

//api import
import { getDashboard } from "../../services/apiServices/MetabaseAPI";
import SomethingWentWrong from "../errorPage/SomethingWentWrong";

const fetchEmbedUrl = async () => {
  const orgId = sessionStorage.getItem("organizationId");

  if (orgId == null) {
    return;
  }

  const response = await getDashboard(
    orgId,
    sessionStorage.getItem("authToken"),
    31,
    sessionStorage.getItem("role")
  ).catch((error) => {
    console.log("Error fetching embed url: ", error);

    return null;
  });

  return response.data.embed_url;
};

export default function LastLogin() {
  const [embedUrl, setEmbedUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  if(sessionStorage.getItem("role") !== "Super Admin"){
    return <div>You are not authorized to see this page</div>
  }
  // fetches dashboard from flask
  // let intervalId; 
  const loadEmbedUrl = async () => {
    try {
      setLoading(true);
      const url = await fetchEmbedUrl();
      setEmbedUrl(url);
      setLoading(false);
      // intervalId = setInterval(initiateHealthCheck, 4000);
    }
    catch (error) {
      setError(true);
      // clearInterval(intervalId); // Stop the interval when an error occurs
    }
  };
  
  // const initiateHealthCheck = async () => {
  //   try {
  //     const isUp = await wakeupMetabase();
  //     if (isUp) {
  //       // wait 5 more seconds to ensure Metabase is fully up
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 9000);
  //       clearInterval(intervalId);
  //     }
  //   } catch (error) {
  //     console.log("Retrying to check Metabase health...");
  //     clearInterval(intervalId); // Stop the interval when an error occurs
  //   }
  // };
  
  useEffect(() => {
    loadEmbedUrl();
  }, []);

  if (error) return <SomethingWentWrong refetch={loadEmbedUrl} />;

  return (
    <div className={styles["last-login-main-container"]}>
      {loading ? (
        <Spin tip="Loading" size="large">
          {content}
        </Spin>
      ) : (
        <iframe src={embedUrl} frameBorder={0} width={"100%"} height={"100%"} />
      )}
    </div>
  );
}
