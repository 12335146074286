import React, { useEffect, useState } from "react";
import { Select, Typography } from "antd";

// styles import
import styles from "./Users.module.scss";

// constant import
import languageConstant from "../../resources/constants/StringConstants";
import { constantUsersList } from "../../utils/ConstantObjects";

// store import
import { shallowEqual, useSelector } from "react-redux";

// component import
import ResponderSubmitterProvider from "./responderAndSubmitterProvider/ResponderSubmitterProvider";
import SubmitterConsumer from "./submitterConsumer/SubmitterConsumer";
import Submitter from "./submitter/Submitter";
import UsersModal from "./UsersModal";

export default function Users() {
 
  const langKey = useSelector((store) => store.config.lang);
  const [selectedRole, setSelectedRole] = useState(constantUsersList[0]?.value);
  const [userList, setUserList] = useState([])

  const { 
    addModalVisible, editModalVisible, submitterAlias, consumerAlias, responderAlias 
  } = useSelector(
    (store) => ({
      addModalVisible: store.users.addModalVisible,
      editModalVisible: store.users.editModalVisible,
      submitterAlias: store.users.submitterAlias,
      consumerAlias: store.users.consumerAlias,
      responderAlias: store.users.responderAlias,
    }),
    shallowEqual
  );

  const getUserLabel = () => {
    constantUsersList[0].label = sessionStorage.getItem("role") == "Super Admin" ?
      `${responderAlias} and ${submitterAlias}`  : submitterAlias;
    constantUsersList[1].label = consumerAlias;

    return (
      constantUsersList.find((user) => user?.value === selectedRole)?.label ||
      languageConstant[langKey].user
    );
  };

  const getUserList = () => {
    constantUsersList[0].label = sessionStorage.getItem("role") == "Super Admin" ?
      `${responderAlias} and ${submitterAlias}`  : submitterAlias;
    constantUsersList[1].label = consumerAlias;
    setUserList([...constantUsersList]); 
  };

  useEffect(() => {
    getUserList();
  },[submitterAlias, consumerAlias, responderAlias]);

  return (
    <div className={styles["users-main-container"]}>
      <div className={styles["users-heading-container"]}>
        <Typography className={styles["users-heading"]}>{getUserLabel()}</Typography>

        <Select
          className={styles["users-select"]}
          variant="filled"
          showSearch={false}
          placeholder={languageConstant[langKey].selectRole}
          value={selectedRole}
          onChange={(value) => {
            setSelectedRole(value);
          }}
          options={userList}
          mode="single"
        ></Select>
      </div>
      {selectedRole === constantUsersList[0]?.value &&
       sessionStorage.getItem("role") == "Super Admin" && <ResponderSubmitterProvider />}
      {selectedRole === constantUsersList[0]?.value &&
       sessionStorage.getItem("role") !== "Super Admin" && <Submitter />}
      {selectedRole === constantUsersList[1]?.value && <SubmitterConsumer />}
      {(addModalVisible || editModalVisible) && <UsersModal />}
    </div>
  );
}
