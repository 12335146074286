import { sites } from "../whitelabel";

export const appliedChatFiltersCount = (chatsPayload) => {
  let count = 0;
  if (chatsPayload?.org_array?.length && sessionStorage.getItem("role") == "Super Admin")
    count += 1;
  if (chatsPayload?.spec_array?.length) count += 1;
  if (chatsPayload?.chat_id?.trim()) count += 1;
  if (chatsPayload?.sub_name?.trim()) count += 1;
  if (chatsPayload?.res_name?.trim()) count += 1;
  if (chatsPayload?.patient_name?.trim()) count += 1;
  if (chatsPayload?.crt_dt?.trim()) count += 1;
  if (chatsPayload?.upd_dt?.trim()) count += 1;
  if (chatsPayload?.close_code?.trim()) count += 1;

  return count;
};

export const appliedSubmitterFiltersCount = (payload) => {
  let count = 0;
  if (payload?.sub_name?.trim()) count += 1;
  if (payload?.npi?.trim()) count += 1;
  if (payload?.medical_group?.trim()) count += 1;

  return count;
};
export const appliedSubmitterConsumerFiltersCount = (payload) => {
  let count = 0;
  if (payload?.patient_name?.trim()) count += 1;
  if (payload?.memberID?.trim()) count += 1;

  return count;
};

export const getClient = () => {
  const { hostname } = window.location;
  const currentSite = sites.find((site) => hostname.indexOf(site.url) > -1);

  return currentSite;
};
