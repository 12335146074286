/* eslint-disable camelcase */
export const PageSizeOptions = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
    disabled: true,
  },
];
// added user_id to avoid unique key props error in map key for
// different components like staff-submitter etc.
export const DummyTableData = [
  {
    id: "1",
    user_id: "1",
    org_id: "1",
  },
  {
    id: "2",
    user_id: "2",
    org_id: "2",
  },
  {
    id: "3",
    user_id: "3",
    org_id: "3",
  },
  {
    id: "4",
    user_id: "4",
    org_id: "4",
  },
  {
    id: "5",
    user_id: "5",
    org_id: "5",
  },
  {
    id: "6",
    user_id: "6",
    org_id: "6",
  },
  {
    id: "7",
    user_id: "7",
    org_id: "7",
  },
  {
    id: "8",
    user_id: "8",
    org_id: "8",
  },
  {
    id: "9",
    user_id: "9",
    org_id: "9",
  },
  {
    id: "10",
    user_id: "10",
    org_id: "10",
  },
];

export const DummyShimmerListData = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
];

export const constantDateAndTimeFormat = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: true,
  timeZoneName: "short",
};

export const constantDateFormat = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

export const constantTimeFormat = {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: true,
};

export const ACTIONS = {
  REMOVE: "remove",
  ADD: "add",
};

export const DATATYPES = {
  notes: "NOTES",
};

export const constantChatPayload = {
  page: 1,
  limit: 10,
  chat_id: "",
  sub_name: "",
  res_name: "",
  patient_name: "",
  close_code: "",
  org_array:
    sessionStorage.getItem("role") !== "Super Admin"
      ? [sessionStorage.getItem("organizationId")]
      : [],
  spec_array: [],
  crt_dt: "",
  upd_dt: "",
};


export const constantReccomendationDropdown = [
  "In-Person Visit",
  "Consultation Complete",
  "In Dialog",
];

export const constantResponderSubmitterProviderPayload = {
  page: 1,
  limit: 10,
  org_id:
    sessionStorage.getItem("role") !== "Super Admin"
      ? sessionStorage.getItem("organizationId")
      : null,
  authToken: sessionStorage.getItem("authToken"),
};

export const constantSubmitterConsumerPayload = {
  page: 1,
  limit: 10,
  patient_name: "",
  memberID: "",
  org_id:
    sessionStorage.getItem("role") !== "Super Admin"
      ? sessionStorage.getItem("organizationId")
      : "",
  authToken: sessionStorage.getItem("authToken"),
};

export const constantSubmitterPayload = {
  page: 1,
  limit: 10,
  npi: "",
  sub_name: "",
  medical_group: "",
  org_id:
    sessionStorage.getItem("role") !== "Super Admin"
      ? sessionStorage.getItem("organizationId")
      : "",
  authToken: sessionStorage.getItem("authToken"),
};

export const constantOrganizationPayload = {
  page: 1,
  limit: 10,
};

export const constantOrgAliasPayload = {
  org_id:
    sessionStorage.getItem("role") !== "Super Admin"
      ? sessionStorage.getItem("organizationId")
      : "",
  providerAlias: "",
  authToken: sessionStorage.getItem("authToken"),
}

export const ConstantPickerDateFormat = "DD-MM-YYYY";

export const constantUsersList =
  sessionStorage.getItem("role") == "Super Admin"
    ? [
      {
        value: "responder-submitter",
        label: "Responder Submitter",
      },
      {
        value: "submitterConsumer",
        label: "Submitter Consumer",
      },
    ]
    : [
      {
        value: "submitter",
        label: "Submitter Provider",
      },
      {
        value: "submitterConsumer",
        label: "Submitter Consumer",
      },
    ];
/* eslint-enable camelcase */
