import { createSlice } from "@reduxjs/toolkit";

// constants import
import { constantUsersList} from "../utils/ConstantObjects";

const UsersSlice = createSlice({
  name: "users",
  initialState: {
    addModalVisible: false,
    editModalVisible: false,
    currentUserModalSelected: constantUsersList[0]?.value,
    /* eslint-disable camelcase */
    submitterAlias: "Submitter Provider",
    responderAlias: "Responder Provider",
    consumerAlias: "Consumer Submitter",
  },
  reducers: {
    changeUsersAddModalVisibility: (state, action) => {
      state.addModalVisible = action.payload;
    },
    changeUsersEditModalVisibility: (state, action) => {
      state.editModalVisible = action.payload;
    },
    changeCurrentSelectedUserModal: (state, action) => {
      state.currentUserModalSelected = action.payload;
    },
    changeOrganizationAlias: (state, action) => {
      /* eslint-disable camelcase */
      if (action.payload["submitter_alias"]) {
        state.submitterAlias = action.payload["submitter_alias"]}
      if (action.payload["responder_alias"]) {
        state.responderAlias = action.payload["responder_alias"]}
      if (action.payload["consumer_alias"]) {
        state.consumerAlias = action.payload["consumer_alias"]}
    },
  },
});

export const {
  changeUsersAddModalVisibility,
  changeUsersEditModalVisibility,
  changeCurrentSelectedUserModal,
  changeOrganizationAlias,
} = UsersSlice.actions;

export default UsersSlice.reducer;

