import { createSlice } from "@reduxjs/toolkit";

// constants import
import { DummyTableData, constantChatPayload } from "../utils/ConstantObjects";

const ConfigSlice = createSlice({
  name: "chats",
  initialState: {
    isLoading: true,
    isError: false,
    chatsTableData: DummyTableData,
    /* eslint-disable camelcase */
    chatsPayload: constantChatPayload,
    /* eslint-enable camelcase */
    totalRecords: 0,
    chatSessionId: null,
    chatsModal: {
      visible: false,
    },
    videoLinkModal: {
      visible: false,
      videoLink: null,
    },
    reassignModal: {
      visible: false,
      /* eslint-disable camelcase */
      payload: {
        specialty: null,
        user_id: null,
        session_id: null,
        org_id: null,
        type: null,
        close_code: null,
        authToken: sessionStorage.getItem("authToken"),
      },
      /* eslint-enable camelcase */
    },
    addResponderModal: {
      visible: false,
      /* eslint-disable camelcase */
      payload: {
        org_id: null,
      },
      /* eslint-enable camelcase */
    },
    removeResponderModal: {
      visible: false,
    },
    filterDrawer: {
      visible: false,
      filterCount: 0,
      filterApplied: false,
    },
  },
  reducers: {
    changeIsLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
    changeIsErrorStatus: (state, action) => {
      state.isError = action.payload;
    },
    changeChatsTableData: (state, action) => {
      state.chatsTableData = action.payload;
    },
    changeTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    changePageNumber: (state, action) => {
      state.chatsPayload.page = action.payload;
    },
    changePageLimit: (state, action) => {
      state.chatsPayload.limit = action.payload;
    },
    changeChatsModalVisibility: (state, action) => {
      state.chatsModal.visible = action.payload;
    },
    changeChatsModalChatSessionId: (state, action) => {
      state.chatSessionId = action.payload;
    },
    changeVideoLinkModalVisibility: (state, action) => {
      state.videoLinkModal.visible = action.payload;
    },
    updateVideoLink: (state, action) => {
      state.videoLinkModal.videoLink = action.payload;
    },
    updateVideoLinkById: (state, action) => {
      const { id, newLink } = action.payload;
      const indexToUpdate = state.chatsTableData.findIndex((chat) => chat.id === id);

      if (indexToUpdate !== -1) {
        /* eslint-disable camelcase */
        const updatedChat = { ...state.chatsTableData[indexToUpdate] };
        updatedChat.video_call_link = newLink;
        /* eslint-enable camelcase */

        return {
          ...state,
          chatsTableData: [
            ...state.chatsTableData.slice(0, indexToUpdate),
            updatedChat,
            ...state.chatsTableData.slice(indexToUpdate + 1),
          ],
        };
      }

      // return original state if chat id not found
      return state;
    },
    /* eslint-disable camelcase */
    changeReassignModalVisibility: (state, action) => {
      state.reassignModal.visible = action.payload;
    },
    changeReassignPayload: (state, action) => {
      state.reassignModal.payload = action.payload;
    },
    changeAddResponderModalVisibility: (state, action) => {
      state.addResponderModal.visible = action.payload;
    },
    changeAddResponderModalPayload: (state, action) => {
      state.addResponderModal.payload.org_id = action.payload;
    },
    changeRemoveResponderModalVisibility: (state, action) => {
      state.removeResponderModal.visible = action.payload;
    },
    changeFilterDrawerVisibility: (state, action) => {
      state.filterDrawer.visible = action.payload;
    },
    changeChatsFilterCount: (state, action) => {
      state.filterDrawer.filterCount = action.payload;
    },
    changeChatsFilterAppliedStatus: (state, action) => {
      state.filterDrawer.filterApplied = action.payload;
    },
    changeFilterOrgIds: (state, action) => {
      state.chatsPayload.org_array = action.payload;
    },
    changeFilterSpecIds: (state, action) => {
      state.chatsPayload.spec_array = action.payload;
    },
    changeFilterChatId: (state, action) => {
      state.chatsPayload.chat_id = action.payload;
    },
    changeFilterSubmiterName: (state, action) => {
      state.chatsPayload.sub_name = action.payload;
    },
    changeFilterResponderName: (state, action) => {
      state.chatsPayload.res_name = action.payload;
    },
    changeFilterConsumerName: (state, action) => {
      state.chatsPayload.patient_name = action.payload;
    },
    changeFilterCreatedDate: (state, action) => {
      state.chatsPayload.crt_dt = action.payload;
    },
    changeFilterUpdatedDate: (state, action) => {
      state.chatsPayload.upd_dt = action.payload;
    },
    changeFilterReccomendation: (state, action) => {
      state.chatsPayload.close_code = action.payload;
    },
    resetChatsPayloadFilter: (state, action) => {
      state.chatsPayload = action.payload;
    },
    resetChatsPayloadOnCancelAndFilterNotApplied: (state) => {
      state.chatsPayload = {
        ...state.chatsPayload,
        chat_id: "",
        sub_name: "",
        res_name: "",
        patient_name: "",
        org_array:
          sessionStorage.getItem("role") !== "Super Admin"
            ? [sessionStorage.getItem("organizationId")]
            : [],
        spec_array: [],
        crt_dt: "",
        upd_dt: "",
        close_code: "",
        authToken: sessionStorage.getItem("authToken"),
      };
    },
    /* eslint-enable camelcase */
  },
});

export const {
  changeIsLoadingStatus,
  changeIsErrorStatus,
  changeChatsTableData,
  changeTotalRecords,
  changePageNumber,
  changePageLimit,
  changeChatsModalVisibility,
  changeChatsModalChatSessionId,
  changeVideoLinkModalVisibility,
  updateVideoLink,
  updateVideoLinkById,
  changeReassignModalVisibility,
  changeReassignPayload,
  changeAddResponderModalVisibility,
  changeAddResponderModalPayload,
  changeRemoveResponderModalVisibility,
  changeFilterDrawerVisibility,
  changeFilterOrgIds,
  changeFilterSpecIds,
  changeFilterChatId,
  changeFilterSubmiterName,
  changeFilterResponderName,
  changeFilterConsumerName,
  resetSelectedDrawerFilter,
  resetChatsPayloadFilter,
  changeChatsFilterCount,
  changeChatsFilterAppliedStatus,
  changeFilterCreatedDate,
  changeFilterUpdatedDate,
  resetChatsPayloadOnCancelAndFilterNotApplied,
  changeFilterReccomendation,
} = ConfigSlice.actions;

export default ConfigSlice.reducer;
